/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = (props) => {
  const {
    id,
    type,
    name,
    customCSS,
    iconSize,
    icon,
    rightIcon,
    clickHandler,
    className,
    action,
    label,
    disabled,
  } = props
  // customCSS needs to be a properly formatted object with CSS
  // Category:Value pairs
  const btnStyle = customCSS

  const iconSizeVal = iconSize || 'sm'
  const iconComponent = icon ? <FontAwesomeIcon icon={icon} size={iconSizeVal} /> : null
  const rightIconComponent = rightIcon ? (
    <>
      &nbsp;
      <FontAwesomeIcon icon={rightIcon} size={iconSizeVal} />
    </>
  ) : null

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      style={btnStyle}
      onClick={clickHandler}
      className={`btn ${className}`}
      data-type={action}
      value={label}
      disabled={disabled}
      type={type}
      name={name}
      id={id}
    >
      {iconComponent}
      {label}
      {rightIconComponent}
    </button>
  )
}

Button.defaultProps = {
  id: '',
  type: 'button',
  name: '',
  className: '',
  customCSS: {},
  icon: null,
  rightIcon: null,
  iconSize: '',
  disabled: false,
  action: '',
}

Button.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  customCSS: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
  rightIcon: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
  iconSize: PropTypes.string,
  disabled: PropTypes.bool,
  action: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Button
