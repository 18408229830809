import React from 'react'
import { Box } from 'reflexbox'
import imgConfetti from './Login/confetti.png'
import imgUserInsights from './Login/user_insights.png'

const LoginMarketingPane = () => {
  return (
    <Box width={[1, 3 / 5]} className="marketing-container login-page">
      <div className="half-circle">
        <div
          className="marketing-content"
          style={{
            backgroundImage: `url(${imgConfetti})`,
            backgroundSize: '80% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'center',
          }}
        >
          <p>
            <span className="new-tag lrg">New feature</span>
          </p>
          <h3 className="title">Introducing our A.I. Analytics & Insights Reporting feature</h3>
          <img className="user-insights" src={imgUserInsights} alt="user insights" />
          <p>
            Say goodbye to manual reporting and hello to <strong>quick user insights</strong>,{' '}
            <strong>summaries and recommendations</strong> powered by A.I. Easily access valuable
            business insights in just a few clicks.
          </p>
          <p>Check our our new feature and upgrade your reporting game!</p>
        </div>
      </div>
    </Box>
  )
}

export default LoginMarketingPane
