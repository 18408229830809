import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import * as Sentry from '@sentry/react'

import initSentry from './sentry'
import FrontEndAnon from './containers/FrontEndAnon'

// Init External Libraries on staging and production
if (process.env.NODE_ENV === 'production') {
  initSentry()
}

// Default Config
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

const App = () => {
  return <FrontEndAnon />
}

const AppWithProfiler = Sentry.withProfiler(App)

ReactDOM.render(<AppWithProfiler />, document.getElementById('root'))

// Hot Reload Module API
if (module.hot) {
  module.hot.accept()
}
