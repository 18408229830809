import React from 'react'

import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

const Task = (props) => {
  const { data } = props

  return (
    // eslint-disable-next-line new-cap
    <div className="task-container">{ReactHtmlParser(data)}</div>
  )
}

Task.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Task
