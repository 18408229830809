import React, { useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import { Auth0Lock } from 'auth0-lock'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
// Components
import loop11Logo from '../../components/logo.svg'
import LoginMarketingPane from './2023/LoginMarketingPane'

const LoginSSO = ({ match }) => {
  const urlParams = new URLSearchParams(window.location.search)

  const { enterpriseConnectionName } = match.params

  const email = urlParams.get('email') || ''
  const location = useLocation()

  useEffect(() => {
    const nextParam = urlParams.get('next')
    let redirectUrl = window.AUTH0_CALLBACK_URL
    if (nextParam) {
      redirectUrl += `?next=${nextParam}`
    }

    // Initializing our Auth0Lock
    const lockOptions = {
      rememberLastLogin: true,
      allowSignUp: true,
      signUp: true,
      loginAfterSignUp: true,

      auth: {
        redirectUrl,
        responseType: 'code',
        sso: true,
      },
      container: 'auth0-login-sso-container',

      // See https://github.com/auth0/lock/blob/master/src/i18n/en.js
      languageDictionary: {
        title: '',
        loginWithLabel: '',
        loginSubmitLabel: 'Log in with SSO',
        error: {
          login: {
            'hrd.not_matching_email':
              // eslint-disable-next-line max-len
              'Single Sign-On has not been configured for your email address or domain yet. Try a different login method or contact your administrator.',
          },
        },
        databaseEnterpriseAlternativeLoginInstructions: '',
      },
      theme: {
        primaryColor: '#00a9a7',
      },
      prefill: {
        email,
      },
    }

    if (enterpriseConnectionName) {
      lockOptions.allowedConnections = [enterpriseConnectionName]
    }

    const lock = new Auth0Lock(window.AUTH0_CLIENT_ID, window.AUTH0_DOMAIN, lockOptions)

    lock.show()

    // returned function will be called on component unmount
    return () => {
      lock.hide()
    }
  }, [])

  return (
    <Flex flexWrap="wrap" justifyContent="center">
      <Box width={[1, 2 / 5]}>
        <div className="signup-container-2023">
          <div className="intro">
            <img src={loop11Logo} alt="Loop11" className="loop11-logo" />

            <h3 className="title">Single Sign-On (SSO)</h3>
          </div>

          <div id="auth0-login-sso-container" />

          <div className="signup-email-wrapper">
            <p>
              <Link to={{ pathname: '/login/', search: location.search }}>
                <i
                  className="fas fa-chevron-left"
                  style={{ verticalAlign: 'middle', marginRight: '4px' }}
                />{' '}
                Other ways to log in
              </Link>
            </p>
          </div>
        </div>
      </Box>

      <LoginMarketingPane />
    </Flex>
  )
}

LoginSSO.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
}

export default LoginSSO
