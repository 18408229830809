import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import reactHtmlParser from 'react-html-parser'

// TODO: Currently the dropdown options at <LI> & having clickHandlers on these violates A11y
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// The below condition has been deprecated
/* eslint-disable jsx-a11y/label-has-for */

const Tooltip = (props) => {
  const {
    onMouseOver,
    icon,
    trigger,
    size,
    click,
    wrapperCSS,
    position,
    message,
    customCSS,
    intercomEvent,
    amplitudeEvent,
  } = props
  let { url } = props
  // if url is a Task image URL (served via Lambda Edge), then add `l11_hide_opacity=1`
  // querystring so that the user could see the image instead of a black page
  if (
    url.startsWith('https://img.staging.loop11.com/page/') ||
    url.startsWith('https://img.loop11.com/page/')
  ) {
    url += '?l11_hide_opacity=1'
  }

  const [displayTooltip, setDisplayTooltip] = useState(false)

  const hideTooltip = (e) => {
    setDisplayTooltip(false)
    if (onMouseOver) {
      // used in additional hover events for parent components
      onMouseOver(e, true)
    }
  }
  const showTooltip = (e) => {
    setDisplayTooltip(true)

    if (onMouseOver) {
      // used in additional hover events for parent components
      onMouseOver(e, false)
    }
  }

  const openLink = () => {
    if (intercomEvent !== null) {
      // eslint-disable-next-line new-cap
      window.Intercom('trackEvent', intercomEvent, { url })
    }
    if (amplitudeEvent !== null) {
      // eslint-disable-next-line no-undef
      amplitude.getInstance().logEvent(amplitudeEvent, {})
    }
    window.open(url)
  }

  let iconDisplay
  let iconClass = ''
  if (icon) {
    iconDisplay = <FontAwesomeIcon icon={icon} className="tooltip-icon" />
    iconClass = 'hide-underline'
  }

  const urlDisplay = url ? (
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={openLink}>
      <br />
      Open in new tab <FontAwesomeIcon icon={icon} />
    </a>
  ) : (
    ''
  )
  const sizeClass = `tt-${size}`

  let spanElem = ''
  if (click) {
    spanElem = (
      <span className={`tooltip-trigger ${iconClass}`} onClick={showTooltip}>
        {trigger} {iconDisplay}
      </span>
    )
  } else {
    spanElem = url ? (
      <span className={`tooltip-trigger ${iconClass}`} onClick={openLink} onMouseOver={showTooltip}>
        {trigger} {iconDisplay}
      </span>
    ) : (
      (spanElem = (
        <span className={`tooltip-trigger ${iconClass}`} onMouseOver={showTooltip}>
          {trigger} {iconDisplay}
        </span>
      ))
    )
  }

  return (
    <span className="tooltip" style={wrapperCSS} onMouseLeave={hideTooltip}>
      {displayTooltip && (
        <div className={`tooltip-bubble tooltip-${position}`}>
          <div className={`tooltip-message ${sizeClass}`} style={customCSS}>
            {reactHtmlParser(message)} {urlDisplay}
          </div>
        </div>
      )}
      {spanElem}
    </span>
  )
}

Tooltip.defaultProps = {
  onMouseOver: false,
  customCSS: {},
  trigger: '',
  position: '',
  wrapperCSS: {},
  click: false,
  size: 's',
  url: '',
  icon: false,
  message: '',
  intercomEvent: null, // intercomEvent to be tracked
  amplitudeEvent: null, // amplitudeEvent to be tracked
}

Tooltip.propTypes = {
  onMouseOver: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
  trigger: PropTypes.any,
  url: PropTypes.string,
  size: PropTypes.string,
  click: PropTypes.bool,
  wrapperCSS: PropTypes.object,
  position: PropTypes.string,
  message: PropTypes.string,
  customCSS: PropTypes.object,
  intercomEvent: PropTypes.string,
  amplitudeEvent: PropTypes.string,
}

export default Tooltip
