import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'
/* eslint-disable import/no-cycle */
import { uniqueKey } from '../../../utilities/base'

const OpenEndedMultiple = (props) => {
  const { data } = props

  return (
    <>
      <div className="question-container">{reactHtmlParser(data.question)}</div>
      <div id="container-testing-reference" className="openended-multiple">
        {data.rows.map((answer, i) => {
          return (
            <div key={uniqueKey(i)}>
              <span>{answer.row}</span>
              <input
                className="preview-input-text"
                name="open-ended-1"
                type="text"
                defaultValue=""
                autoComplete="off"
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

OpenEndedMultiple.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OpenEndedMultiple
