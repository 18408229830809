/* eslint-disable import/prefer-default-export */

export const PLAN_TYPE_STANDARD = 1
export const PLAN_TYPE_TEAM = 2

export const SAMPLE_USER_TEST_UUID = 'ced840bb-086e-43c2-a921-9a24406f4812'

export const STATUS_CLOSED = 'closed'
export const STATUS_LAUNCHED = 'launched'
export const STATUS_DRAFT = 'draft'

export const INVITE_LINK = 'link'
export const INVITE_POPUP = 'popup'
export const INVITE_PANEL_TESTINGTIME = 'panel_testingtime'
export const INVITE_PANEL_USERINTERVIEWS = 'panel_userinterviews'
export const INVITE_PANEL_LOOP11 = 'panel_loop11'
export const INVITE_PANEL_PROLIFIC = 'panel_prolific'
