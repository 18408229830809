import React from 'react'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip'

const Label = props => {
  const { tooltip, name, helpText, sibling } = props

  let tooltipDisplay =
    tooltip && Object.keys(tooltip).length > 0 ? (
      <Tooltip
        trigger={tooltip.trigger}
        message={tooltip.message}
        click={tooltip.click}
        position={tooltip.position}
        size={tooltip.size}
        icon={faQuestionCircle}
      ></Tooltip>
    ) : (
      ''
    )

  return (
    <label htmlFor={sibling ? sibling : name} className="form-label">
      {name}
      <span>{helpText}</span>
      {tooltipDisplay}
    </label>
  )
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  tooltip: PropTypes.object,
}

export default Label
