import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useTrackingGA = (trackingId) => {
  const { listen } = useHistory()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const unlisten = listen(() => {
      setTimeout(() => {
        if (window.gtag) {
          window.gtag('config', trackingId, {
            page_title: window.document.title,
            page_location: window.location.href,
            page_path: window.location.pathname,
          })
        }
      }, 1000)
    })

    return unlisten
  }, [trackingId, listen])
}

export default useTrackingGA
