import React, { useState } from 'react'
import { useInput, useBoolean } from 'react-hanger/array'
import { Flex, Box } from 'reflexbox'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
// Components
import { validateEmail } from '../../utilities/base'
import InputText from '../../components/form/InputText'
import Button from '../../components/form/Button'
import loop11Logo from '../../components/logo.svg'
import LoginMarketingPane from './2023/LoginMarketingPane'

const ForgotPassword2023 = () => {
  // eslint-disable-next-line no-unused-vars
  const [loading, loadingActions] = useBoolean(false)

  const [[email], emailActions] = useInput('')

  const [errorObj, setErrorObj] = useState(null)

  const [success, successActions] = useBoolean(false)
  const [errorMsg, setErrorMsg] = useState(null)

  const location = useLocation()

  const submitForm = (e) => {
    e.preventDefault()
    loadingActions.setTrue()
    setErrorObj(null)
    axios
      .post('/api/account/auth/forgot_password/', { email })
      .then((resp) => {
        successActions.setValue(resp.data.success)
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const resp = error.response.data
          if (resp.detail) {
            setErrorMsg(resp.detail)
          } else {
            setErrorObj(resp)
          }
        }
      })
      .finally(() => {
        loadingActions.setFalse()
      })
  }

  return (
    <form onSubmit={submitForm}>
      <Flex flexWrap="wrap" justifyContent="center">
        <Box width={[1, 2 / 5]}>
          <div className="signup-container-2023">
            <div className="intro">
              <img src={loop11Logo} alt="Loop11" className="loop11-logo" />

              <h3 className="title">Forgot your password</h3>
            </div>

            <div className="signup-email-wrapper">
              {success && (
                <>
                  <p>Thanks, check your email for instructions to reset your password.</p>
                  <p>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Didn't get the email? Make sure you enter your correct email address, or check
                    your spam folder, or try again.
                  </p>
                  <p>
                    <Link to="/login/">Log in</Link>
                  </p>
                </>
              )}

              {!success && (
                <>
                  <p>
                    Enter the email address associated with your account <br />
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    and we'll send you a link to reset your password.
                  </p>

                  <div>
                    <InputText
                      useBlock
                      type="text"
                      label=""
                      name="email"
                      value={email}
                      placeholder="Email"
                      handleChange={emailActions.onChange}
                      className="inpt-lrg"
                      error={(email && !validateEmail(email)) || (errorObj && errorObj.email)}
                      errorMsg={
                        (errorObj && errorObj.email && errorObj.email[0]) || 'Invalid email'
                      }
                      focus={errorObj && errorObj.email}
                    />
                    <p className="error">{errorMsg}</p>
                    <Button
                      label="Reset password"
                      clickHandler={submitForm}
                      disabled={!email || !validateEmail(email)}
                    />
                  </div>
                  <p>
                    <Link to={{ pathname: '/login/', search: location.search }}>
                      <i
                        className="fas fa-chevron-left"
                        style={{ verticalAlign: 'middle', marginRight: '4px' }}
                      />{' '}
                      Log in
                    </Link>
                  </p>
                </>
              )}
            </div>
          </div>
        </Box>

        <LoginMarketingPane />
      </Flex>
    </form>
  )
}

export default ForgotPassword2023
