import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'
/* eslint-disable import/no-cycle */
import { uniqueKey } from '../../../utilities/base'

const NetPromoterScore = (props) => {
  const { data } = props

  return (
    <>
      <div className="question-container">{reactHtmlParser(data.question)}</div>
      <div className="preview-lists net-promoter-score">
        <select>
          <option>PLEASE SELECT...</option>
          {data.columns.map((answer, i) => {
            return (
              <option key={uniqueKey(i)} value={answer.column}>
                {answer.column}
              </option>
            )
          })}
        </select>
      </div>
    </>
  )
}

NetPromoterScore.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NetPromoterScore
