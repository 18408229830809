import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export default function initSentry() {
  Sentry.init({
    dsn: 'https://a200e543d44f40e78c9050724913e581@o297844.ingest.sentry.io/3532007',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // Be sure to lower this in production
    environment: window.location.hostname,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    blacklistUrls: [
      // NewRelic error logging
      /js-agent\.newrelic\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Firefox extensions
      /^resource:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    ignoreUrls: [/www\.googletagmanager\.com/],
  })

  // capture the user
  if (window.l11User && window.l11User.email) {
    Sentry.configureScope((scope) => {
      scope.setUser({ email: window.l11User.email })
    })
  }
}
