/* eslint-disable max-len */
import React from 'react'
import reactHtmlParser from 'react-html-parser'

const loadingMessages = [
  'Begin your project in Loop11 by clearly defining your testing goals. This ensures focused and effective results.',
  'Did you know in Loop11 you can select from a wide range of demographics from our integrated UX panels to find your ideal test participants?',
  "Effective Loop11 projects have clear, concise tasks. Try to phrase your tasks in a way that mimics the user's natural workflow.",
  'Quick start your project using Loop11’s templates. They’re designed to cover various types of user research needs.',
  'Remember to add detailed instructions for your participants. Clear guidance leads to more accurate results in Loop11.',
  'Loop11 allows testing of interactive prototypes. Ensure your prototype links are working before launching the test.',
  'Use Loop11’s heatmap feature to visually understand where users click most, helping to identify usability issues.',
  'After completing a test, dive into Loop11’s AI analytics to gain deep insights into user behavior and preferences.',
  'Loop11 supports iterative testing. Use initial feedback to make changes and retest for continuous improvement.',
  'Share your Loop11 project results with your team effortlessly. Collaboration is key to making informed design decisions.',
]

const getMsg = () => {
  return loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
}

const LoadingMessage = () => {
  return (
    <div id="loadingContainer">
      <div>
        <h1>
          Loading
          <span className="ellipsis-animation" />
        </h1>
        <h4 style={{ maxWidth: '600px' }}>{reactHtmlParser(getMsg())}</h4>
      </div>
    </div>
  )
}

export default LoadingMessage
