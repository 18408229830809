import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { faCopy } from '@fortawesome/pro-light-svg-icons'
import reactHtmlParser from 'react-html-parser'
import Label from './Label'
import Button from './Button'
/* eslint-disable import/no-cycle */
import { stripSpacesAndLowerCase } from '../../utilities/base'

const InputText = (props) => {
  const {
    value,
    focus,
    error,
    code,
    label,
    helpText,
    tooltip,
    className,
    type,
    name,
    handleChange,
    handleOnBlur,
    blurOnly,
    keyboardEvent,
    placeholder,
    step,
    min,
    max,
    disabled,
    readonly,
    errorMsg,
    warningMsg,
    onClick,
    useNonDefault,
    useBlock,
  } = props

  const inputEl = useRef()
  const [showTooltip, setShowTooltip] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)
  const strippedName = stripSpacesAndLowerCase(name)
  const hideTooltipTimeout = useRef(null)

  const [showPassword, setShowPassword] = useState(false)
  const [inputType, setInputType] = useState(type)

  useEffect(() => {
    if (focus) {
      inputEl.current.focus()
    }
  })

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  let classes = 'form-group'
  classes += error ? ' error' : ''
  classes += code ? ' code-block' : ''
  classes += useBlock ? ' useBlock' : ''

  const copyCode = (e) => {
    const copyString = e.target.closest('.form-group').querySelector('input').getAttribute('value')

    const textArea = document.createElement('textarea')
    textArea.value = copyString
    document.body.appendChild(textArea)

    textArea.select()
    document.execCommand('Copy')
    textArea.remove()

    setShowTooltip(true)
    hideTooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false)
    }, 3000)
  }

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      if (hideTooltipTimeout) {
        clearTimeout(hideTooltipTimeout.current)
      }
    }
  }, [])

  const highlightText = (e) => {
    const focusEl = e.target.closest('.form-group').querySelector('input')
    focusEl.focus()
    focusEl.select()
  }

  const commonAttributes = {
    className,
    name,
    type: inputType,
    ref: inputEl,
    onChange: blurOnly ? null : handleChange,
    onBlur: handleOnBlur === null ? handleChange : handleOnBlur,
    onKeyPress: keyboardEvent,
    placeholder,
    autoComplete: 'off',
    disabled,
  }
  const commonAttributesNumber = {
    ...commonAttributes,
    id: strippedName,
    step: step || '1',
    min: min || '1',
    max: max || '999',
  }

  const commonAttributesText = {
    ...commonAttributes,
    id: name,
    readOnly: readonly,
    onClick: readonly ? highlightText : onClick,
  }

  return (
    <div className={classes}>
      {label && <Label name={label} sibling={strippedName} helpText={helpText} tooltip={tooltip} />}
      {(className === 'inpt-step' || type === 'number') && (
        <>
          {!useNonDefault && <input {...commonAttributesNumber} defaultValue={currentValue} />}
          {useNonDefault && <input {...commonAttributesNumber} value={currentValue} />}
        </>
      )}
      {className !== 'inpt-step' && type !== 'number' && (
        <>
          {!useNonDefault && <input {...commonAttributesText} defaultValue={currentValue} />}
          {useNonDefault && <input {...commonAttributesText} value={currentValue} />}
        </>
      )}

      {/* show/hide password */}
      {type === 'password' && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <i
          className={`icon-right fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setInputType(showPassword ? 'password' : 'text')
            setShowPassword(!showPassword)
          }}
        />
      )}

      {type !== 'password' && disabled && <i className="icon-right far fa-lock" />}

      {/* if help text is provided without label, show the help text below the input */}
      {!label && helpText && (
        <p className="gray" style={{ marginTop: 0 }}>
          <small>{helpText}</small>
        </p>
      )}

      {code && (
        <>
          {showTooltip && (
            <span className="tooltip lower">
              <div className="tooltip-bubble tooltip-left">
                <div className="tooltip-message tt-xs">Copied!</div>
              </div>
            </span>
          )}
          <Button label="" icon={faCopy} iconSize="lg" clickHandler={copyCode} />
        </>
      )}
      {error && <p className="error-val">{errorMsg}</p>}
      {warningMsg && <p className="warning">{reactHtmlParser(warningMsg)}</p>}
    </div>
  )
}

InputText.defaultProps = {
  error: false,
  errorMsg: '',
  warningMsg: null,
  tooltip: {},
  value: '',
  helpText: '',
  label: '',
  placeholder: '',
  name: '',
  handleChange: null,
  handleOnBlur: null,
  className: '',
  code: false,
  focus: false,
  keyboardEvent: null,
  blurOnly: false,
  step: '',
  min: 0,
  max: 0,
  disabled: false,
  readonly: false,
  useNonDefault: false,
  useBlock: false,
  onClick: (f) => f,
}

InputText.propTypes = {
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  warningMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  handleChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  code: PropTypes.bool,
  focus: PropTypes.bool,
  keyboardEvent: PropTypes.func,
  blurOnly: PropTypes.bool,
  step: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  useNonDefault: PropTypes.bool,
  useBlock: PropTypes.bool,
  onClick: PropTypes.func,
}

export default InputText
