import React from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes as faTimesLight } from '@fortawesome/pro-light-svg-icons'
import {
  faExclamationTriangle,
  faBan,
  faCheckCircle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const Alert = props => {
  const alertClass = `alert-${props.type}`

  let alertIcon = ''

  switch (props.type) {
    case 'WARNING':
      alertIcon = <FontAwesomeIcon icon={faExclamationTriangle} />
      break
    case 'ERROR':
      alertIcon = <FontAwesomeIcon icon={faBan} />
      break
    case 'SUCCESS':
      alertIcon = <FontAwesomeIcon icon={faCheckCircle} />
      break
    default:
      alertIcon = <FontAwesomeIcon icon={faInfoCircle} />
      break
  }

  const divStyle = {
    zIndex: 15 - props.id,
  }

  return ReactDOM.createPortal(
    <div className={alertClass} style={divStyle}>
      <span data-name="spacer" />
      <div>
        {alertIcon} {props.msg}
      </div>
      <span onClick={() => props.remove(props.id)}>
        <FontAwesomeIcon icon={faTimesLight} />
        <FontAwesomeIcon icon={faTimes} />
      </span>
    </div>,
    document.getElementById('alert-container')
  )
}

export default Alert
