import React, { useEffect, useState } from 'react'
import { useInput, useBoolean } from 'react-hanger/array'
import { Flex, Box } from 'reflexbox'
import { Auth0Lock } from 'auth0-lock'
import { Link, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
// Components
import { validateEmail } from '../../utilities/base'
import InputText from '../../components/form/InputText'
import Button from '../../components/form/Button'
import loop11Logo from '../../components/logo.svg'
import LoginMarketingPane from './2023/LoginMarketingPane'
import { useAlerts } from '../../components/alert_anon_manager'

const Login2023 = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const { showAlert } = useAlerts()
  const [loading, loadingActions] = useBoolean(false)

  const [[email], emailActions] = useInput(urlParams.get('email') || '')
  const [[password], passwordActions] = useInput('')

  const [errorObj, setErrorObj] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)

  const location = useLocation()
  const history = useHistory()

  const canEnableLoginBtn = () => {
    return !loading && email && validateEmail(email) && password
  }

  const submitForm = (e) => {
    e.preventDefault()
    loadingActions.setTrue()
    setErrorObj(null)
    setErrorMsg(null)
    axios
      .post(`/api/account/auth/login/${window.location.search}`, {
        email,
        password,
      })
      .then((resp) => {
        // if user enables 2FA -> need to verify 2FA
        if (resp.data.two_factor_required) {
          const deviceType = resp.data.devices[0].type
          history.push(`/verify-2fa/?type=${deviceType}`)
        } else {
          window.location.href = resp.data.redirect_url || '/dashboard/'
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const resp = error.response.data
          if (resp.detail) {
            setErrorMsg(resp.detail)
          } else {
            setErrorObj(resp)
          }
        }
      })
      .finally(() => {
        loadingActions.setFalse()
      })
  }

  useEffect(() => {
    const queryParamError = urlParams.get('error')
    // eslint-disable-next-line max-len
    const queryParamErrorDesc =
      urlParams.get('error_description') || 'There was an authentication error. Please try again.'

    if (queryParamError) {
      showAlert('ERROR', queryParamErrorDesc, true)
    }

    const nextParam = urlParams.get('next')
    let redirectUrl = window.AUTH0_CALLBACK_URL
    if (nextParam) {
      redirectUrl += `?next=${nextParam}`
    }

    // Initializing our Auth0Lock
    const lockOptions = {
      rememberLastLogin: true,
      allowSignUp: true,
      signUp: true,
      loginAfterSignUp: true,

      auth: {
        redirectUrl,
        responseType: 'code',
        sso: false,
      },
      allowedConnections: ['google-oauth2', 'windowslive', 'linkedin'],
      container: 'auth0-login-container',

      // See https://github.com/auth0/lock/blob/master/src/i18n/en.js
      languageDictionary: {
        title: '',
        loginWithLabel: '',
        loginSubmitLabel: 'Sign up with Single Sign-On (SSO)',
        error: {
          login: {
            'hrd.not_matching_email':
              // eslint-disable-next-line max-len
              'Single Sign-On has not been configured for your email address or domain yet. Try a different login method or contact your administrator.',
          },
        },
      },
      theme: {
        primaryColor: '#00a9a7',
      },
      prefill: {
        email,
      },
    }
    const lock = new Auth0Lock(window.AUTH0_CLIENT_ID, window.AUTH0_DOMAIN, lockOptions)

    lock.show()

    // returned function will be called on component unmount
    return () => {
      lock.hide()
    }
  }, [])

  return (
    <form onSubmit={submitForm}>
      <Flex flexWrap="wrap" justifyContent="center">
        <Box width={[1, 2 / 5]}>
          <div className="signup-container-2023">
            <div className="intro">
              <img src={loop11Logo} alt="Loop11" className="loop11-logo" />

              <h3 className="title">Log in to your account</h3>
              <ul className="why-signup">
                <li>
                  <i className="far fa-usd-circle" /> Like to get paid for testing websites?{' '}
                  <a href="/dashboard/cint/apply/" className="link">
                    Apply here
                  </a>
                </li>
              </ul>
            </div>

            <h5 className="social-title">Log in with a business account</h5>
            <div id="auth0-login-container" />

            <div className="separator">
              <hr className="separator-line" />
              <span className="separator-text">Or log in using email</span>
            </div>

            <div className="signup-email-wrapper">
              <div>
                <InputText
                  type="text"
                  helpText="Use a business email address. Personal or disposable ones cannot be used."
                  label=""
                  name="email"
                  value={email}
                  placeholder="username@your-business.com"
                  handleChange={emailActions.onChange}
                  blurOnly
                  className="inpt-signup"
                  error={(email && !validateEmail(email)) || (errorObj && errorObj.email)}
                  errorMsg={(errorObj && errorObj.email && errorObj.email[0]) || 'Invalid email'}
                  focus={(errorObj && errorObj.email) || (!email && !urlParams.get('email'))}
                  disabled={loading}
                />
                <InputText
                  type="password"
                  helpText="Use 8 or more characters with a mix of letters, numbers & symbols"
                  label=""
                  name="password"
                  value={password}
                  placeholder="Password"
                  handleChange={passwordActions.onChange}
                  className="inpt-signup"
                  error={errorObj && errorObj.password}
                  errorMsg={errorObj && errorObj.password && errorObj.password[0]}
                  focus={(errorObj && errorObj.password) || urlParams.get('email')}
                  disabled={loading}
                />

                <p className="forgot-password">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <Link to={{ pathname: '/forgot-password/', search: location.search }}>
                    Forgot password?
                  </Link>
                </p>

                <p className="error">{errorMsg}</p>
                <Button
                  type="submit"
                  className="signup"
                  label="Log in"
                  clickHandler={submitForm}
                  disabled={!canEnableLoginBtn()}
                />
                <p>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Don't have an account?{' '}
                  <Link to={{ pathname: '/signup/', search: location.search }}>Sign up now</Link>.
                </p>
              </div>
            </div>

            <div className="separator">
              <hr className="separator-line" />
            </div>

            <div>
              <Button
                className="outline slim"
                label="Single Sign-On (SSO)"
                clickHandler={() => {
                  history.push(`/login/sso/`)
                }}
              />
            </div>
          </div>
        </Box>

        <LoginMarketingPane />
      </Flex>
    </form>
  )
}

export default Login2023
