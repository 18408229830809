/* eslint-disable no-undef */
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const PATHNAME_SIGNUP = '/signup/'

const useTrackingFBPixel = (trackingId) => {
  const { listen } = useHistory()
  const location = useLocation()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const unlisten = listen((newLocation) => {
      // Facebook Pixel
      fbq('track', 'PageView')
    })

    return unlisten
  }, [trackingId, listen])

  // Track Lead: Triggers when someone lands on the Sign up page (before they enter their details)
  if (location.pathname === PATHNAME_SIGNUP) {
    fbq('track', 'Lead')
  }
}

export default useTrackingFBPixel
