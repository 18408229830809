import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
/* eslint-disable import/no-cycle */
import { uniqueKey } from '../../../utilities/base'

import InputText from '../../form/InputText'

const MultieChoice1 = props => {
  const { data } = props

  return (
    <React.Fragment>
      <div className="question-container">{reactHtmlParser(data.question)}</div>
      <ul className="preview-lists">
        {data.rows.map((answer, i) => {
          return (
            <li key={uniqueKey(i)}>
              <FontAwesomeIcon icon={faCircle} className="close-modal" />
              {answer.row}
            </li>
          )
        })}
        {data.commentOption && (
          <li>
            <FontAwesomeIcon icon={faCircle} className="close-modal" />
            {data.commentText}
            <InputText
              type="text"
              label=""
              name="name"
              value=""
              placeholder=""
              className="inpt-med"
              error={false}
              errorMsg=""
            />
          </li>
        )}
      </ul>
    </React.Fragment>
  )
}

MultieChoice1.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MultieChoice1
