import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'

const OpenEndedVerbal = (props) => {
  const { data } = props

  return (
    <>
      <div className="question-container">{reactHtmlParser(data.question)}</div>
    </>
  )
}

OpenEndedVerbal.propTypes = {
  data: PropTypes.object.isRequired,
}

export default OpenEndedVerbal
