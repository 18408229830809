import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
/* eslint-disable import/no-cycle */
import { uniqueKey } from '../../../utilities/base'

const RatingScaleMulti = (props) => {
  const { data } = props

  return (
    <>
      <div className="question-container">{reactHtmlParser(data.question)}</div>
      <ul className="preview-lists rating-scale">
        {data.rows.map((subject, i) => {
          return (
            <li key={uniqueKey(i)}>
              <p>{subject.row}</p>
              {data.columns.length <= 5 && (
                <ul className="matrix-list">
                  {data.columns.map((answer, n) => {
                    return (
                      <li key={uniqueKey(n)}>
                        <FontAwesomeIcon icon={faCircle} className="close-modal" />
                        <br />
                        {answer.column}
                      </li>
                    )
                  })}
                </ul>
              )}
              {data.columns.length > 5 && (
                <select>
                  <option>PLEASE SELECT...</option>
                  {data.columns.map((answer, iter) => {
                    return (
                      <option key={uniqueKey(iter)} value={answer.column}>
                        {answer.column}
                      </option>
                    )
                  })}
                </select>
              )}
            </li>
          )
        })}
      </ul>
    </>
  )
}

RatingScaleMulti.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RatingScaleMulti
