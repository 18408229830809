import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import { Box } from 'reflexbox'
import * as Sentry from '@sentry/react'

import '../scss/application.scss'
// Components and so on ...
import LoadingMessage from '../components/LoadingMessage'
import AlertAnonProvider from '../components/alert_anon_manager'
import useTrackingGA from '../core/useTrackingGA'
import useTrackingFBPixel from '../core/useTrackingFBPixel'
import Signup2023 from '../pages/auth/Signup2023'
import Login2023 from '../pages/auth/Login2023'
import LoginSSO from '../pages/auth/LoginSSO'
import ForgotPassword2023 from '../pages/auth/ForgotPassword2023'
import Verify2FA from '../pages/auth/Verify2FA'

const theme = {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
}

const ErrorBoundaryFallback = () => {
  return <Box p={3}>An unexpected error has occurred. Please refresh the page.</Box>
}

const App = () => {
  useTrackingGA(window.GA_TRACKING_ID)
  useTrackingFBPixel(window.FB_PIXEL_ID)

  return (
    <Suspense fallback={<LoadingMessage />}>
      <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />} showDialog>
        <Switch>
          <Route exact path="/signup/" component={Signup2023} />
          <Route exact path="/login/" component={Login2023} />
          <Route exact path="/verify-2fa/" component={Verify2FA} />
          <Route exact path="/login/sso/" component={LoginSSO} />
          <Route exact path="/login/sso/:enterpriseConnectionName/" component={LoginSSO} />
          <Route exact path="/forgot-password/" component={ForgotPassword2023} />
        </Switch>
      </Sentry.ErrorBoundary>
    </Suspense>
  )
}

const FrontEndAnon = () => {
  return (
    <ThemeProvider theme={theme}>
      <AlertAnonProvider>
        <BrowserRouter basename="/dashboard">
          <App />
        </BrowserRouter>
      </AlertAnonProvider>
    </ThemeProvider>
  )
}

export default FrontEndAnon
