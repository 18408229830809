import React, { useRef, useEffect } from 'react'
import { useArray } from 'react-hanger/array'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import reactHtmlParser from 'react-html-parser'
import Alert from './Alert'
import Banner from './Banner'

const AlertAnonContext = React.createContext({})

// Provider
// ==============================
/* Adapted from https://github.com/schiehll/react-alert/blob/master/src/Provider.js */

const AlertAnonProvider = ({ children }) => {
  const root = useRef(null)
  const alertContext = useRef(null)

  const MAX_NUM_ALERTS = 3
  const [alerts, alertsActions] = useArray([])
  const [banners, bannersActions] = useArray([])

  useEffect(() => {
    root.current = document.createElement('div')
    document.body.appendChild(root.current)

    return () => {
      if (root.current) document.body.removeChild(root.current)
    }
  }, [])

  // set time limit in which alerts should disappear. Set to 10 seconds
  const TIME_LIMIT = 10000
  const removeAlert = (id) => {
    // remove the alert that corresponds with id parameter
    alertsActions.removeById(id)
  }

  const monitorAlerts = () => {
    // Cycle through all alerts & if their timeout has passed, then schedule them for removal
    alerts.forEach((alert) => {
      if (alert.timeout && alert.timeout + TIME_LIMIT < Date.now()) {
        removeAlert(alert.id)
      }
    })
  }

  useEffect(() => {
    // when a new alert is set, we also schedule a monitor function to remove it
    if (alerts.length > 0) {
      setTimeout(() => {
        monitorAlerts()
      }, TIME_LIMIT + 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts])

  // API to show new alert
  const showAlert = (type, msg, noTimeout) => {
    const newAlert = {
      type,
      msg: reactHtmlParser(msg),
      id: Date.now(),
      timeout: noTimeout ? null : Date.now(),
    }
    alertsActions.push(newAlert)
  }

  // API to show new banner
  const showBanner = (msg, linkTo) => {
    bannersActions.setValue([
      {
        type: 'BANNER',
        msg,
        id: Date.now(),
        linkTo,
      },
    ])
  }

  const getBanners = () => {
    return banners
  }

  alertContext.current = {
    showAlert,
    showBanner,
    getBanners,
  }

  return (
    <AlertAnonContext.Provider value={alertContext}>
      {root.current &&
        createPortal(
          <>
            {banners.map((banner) => (
              <Banner
                key={banner.id}
                id={banner.id}
                type={banner.type}
                msg={banner.msg}
                linkTo={banner.linkTo}
              />
            ))}
            {alerts.slice(-MAX_NUM_ALERTS).map((alert) => (
              <Alert
                key={alert.id}
                id={alert.id}
                remove={removeAlert}
                type={alert.type}
                msg={alert.msg}
              />
            ))}
          </>,
          root.current
        )}
      {children}
    </AlertAnonContext.Provider>
  )
}

AlertAnonProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default AlertAnonProvider

// Consumer
// ==============================

export const useAlerts = () => React.useContext(AlertAnonContext).current
