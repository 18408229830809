import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCommentAlt as faCommentAltL,
  faCommentsAlt as faCommentsAltL,
} from '@fortawesome/pro-light-svg-icons'

// component for the preview modal
// import preview components
/* eslint-disable import/no-cycle */
import MultiChoice1 from '../components/study-management/preview/MultiChoice1'
import MultiChoiceMulti from '../components/study-management/preview/MultiChoiceMulti'
import SystemUsabilityScore from '../components/study-management/preview/SystemUsabilityScore'
import RatingScaleMulti from '../components/study-management/preview/RatingScaleMulti'
import RatingScale1 from '../components/study-management/preview/RatingScale1'
import Ranking from '../components/study-management/preview/Ranking'
import OpenEnded1 from '../components/study-management/preview/OpenEnded1'
import OpenEndedMultiple from '../components/study-management/preview/OpenEndedMultiple'
import OpenEndedComments from '../components/study-management/preview/OpenEndedComments'
import OpenEndedVerbal from '../components/study-management/preview/OpenEndedVerbal'
import NetPromoterScore from '../components/study-management/preview/NetPromoterScore'
import Task from '../components/study-management/preview/Task'
import { STATUS_CLOSED, STATUS_DRAFT, STATUS_LAUNCHED } from '../core/const'

const cloneDeep = require('clone-deep')
// Moment used for parsing dates
const moment = require('moment')

const validator = require('validator')

export const codeWidth = (string) => {
  // if (typeof string === "undefined"){ return {width: "0px"};}
  const codeLength = string.length
  return { width: `${codeLength * 9}px` }
}

export function toggleModal() {
  const { body } = document
  const modal = document.getElementById('modal-container')

  body.classList.toggle('disable-scroll')
  modal.classList.toggle('show-modal')

  if (modal.classList.contains('show-modal')) {
    // Slight delay required in order to make fadein of modal smooth
    setTimeout(() => {
      modal.style.opacity = 1
    }, 100)
  } else {
    modal.style.opacity = 0
  }
}

export const toggle_tag = (tag, current_tags) => {
  /*
    All tags must have at least a name, ... they most likey a uuid too.
  */

  const selected_tags = cloneDeep(current_tags)

  // Could use uuid, but 'name' is always unqiue, it makes no sense to tag
  // something with the same tag ('name') twice.
  const i = current_tags
    .map((e) => {
      return e.name
    })
    .indexOf(tag.name)

  if (i === -1) {
    // add it, since it's not already there
    selected_tags.push(tag)
  } else {
    // remove it
    selected_tags.splice(i, 1)
  }

  return selected_tags
}

export function updateArray(project, projectArray) {
  const selectedProjects = projectArray

  const i = selectedProjects.indexOf(project)
  if (i === -1) {
    selectedProjects.push(project)
  } else {
    selectedProjects.splice(i, 1)
  }

  return [...selectedProjects]
}
export function getCommonElements(array) {
  let commonArr = array.length < 1 ? [] : array[0]

  const findCommon = (commonArray, arr) => {
    return commonArray.filter((x) => arr.includes(x))
  }

  if (array.length > 1) {
    // only need to loop over if multiple arrays
    for (let i = 1; i < array.length; i += 1) {
      commonArr = findCommon(commonArr, array[i])
    }
  }
  // return an array of shared elements
  return commonArr
}

export function isDescendant(child, parent) {
  const node = child.closest(parent)
  if (node != null) {
    return true
  }
  //  closest`will not work in IE. Currently backend usage of IE
  // equats to less than 0.4%. However, polyfill exists at
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
  return false
}

export function convertDate(timestamp, utc = false, format = null) {
  if (utc) {
    const offset = new Date().getTimezoneOffset() / 60
    const adjustedTime = timestamp.replace('+00', offset)
    const fmt = format === null ? 'h:mma D MMM YYYY' : format

    return moment.utc(adjustedTime).format(fmt)
  }
  const fmt = format === null ? 'D MMM YYYY' : format
  return moment.unix(timestamp).format(fmt)
}

export function eventListenerControl(bool, func) {
  if (bool) {
    // Init listener for clicks to assist with toggling list
    window.addEventListener('click', func)
  } else {
    // if the list is closed, then remove event listeners
    window.removeEventListener('click', func)
  }
}

export const striptags = (string) => {
  return string.replace(/(<([^>]+)>)/gi, '')
}

export function truncateString(string, limit, end, lineBreak = false) {
  // first, strip tags
  let strippedString = striptags(string)
  if (lineBreak && strippedString.length > 35) {
    // force a better line breaking for tooltip url display
    strippedString = `${strippedString.substring(0, 36)} </br>${strippedString.substring(
      36,
      strippedString.length
    )}`
  }

  return strippedString && strippedString.length > limit
    ? `${strippedString.slice(0, end)} ...`
    : strippedString
}

export const stripSpacesAndLowerCase = (string) => {
  return string
    .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '')
    .replace(/ /g, '-')
    .toLowerCase()
}

export function getDevice(code) {
  const devices = {
    m: 'Mobile',
    d: 'Desktop',
    t: 'Tablet',
  }
  return devices[code]
}

export function formatText(string) {
  return string
    .toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export function buildVideoLink(data) {
  let buildLinks = ''

  if (typeof data === 'object') {
    if ('id' in data) {
      buildLinks += `<a href="${data.id}" target="_blank" rel="noopener noreferrer">
      ${formatText(data.id)}</a>`
    }
  }

  return buildLinks
}

export const formatFloat = (value) => {
  const setValue = typeof value === 'string' ? parseInt(value, 10) : value

  if (setValue === undefined || setValue === null) {
    return '-'
  }

  if (setValue % 1 > 0) {
    // if we have decimal place then format to 2 places
    return setValue.toFixed(2)
  }
  // else if a whole number, return no decimals
  return setValue.toFixed(0)
}

export function projectTypeAndDates(moderated, launched_at, modified_at, created_at) {
  let timestamp1
  let timestamp2

  if (launched_at) {
    timestamp1 = `Modified <span>${modified_at}</span>`
    timestamp2 = `Launched <span>${launched_at}</span>`
  } else {
    timestamp1 = `Created <span>${created_at}</span>`
    timestamp2 = modified_at ? `Modified <span>${modified_at}</span>` : ''
  }

  return {
    iconT: moderated ? faCommentsAltL : faCommentAltL,
    timestamp1,
    timestamp2,
  }
}

export const calculateContentHeight = () => {
  const modalHeight = document.getElementById('modal-body').offsetHeight
  const h1Height = document.querySelector('#modal-body > h1').offsetHeight
  const controlsHeight = document.getElementById('modal-controls').offsetHeight

  const modalContentHeight = modalHeight - h1Height - controlsHeight
  // Set the height of the content
  document.getElementById('modal-content').style.height = `${modalContentHeight} px`

  return modalContentHeight
}

export const chartColors = () => {
  return ['#2FC8AD', '#00A9A7', '#3E4E67', '#FFD854', '#FF9A9A', '#FF8080']
}

export const taskResultColors = () => {
  return ['#00A9A7', '#FF9A9A', '#E6F3F0']
}

export const npsColors = () => {
  return {
    promoter: '#00A9A7',
    passive: '#FFD854',
    detractor: '#ff9a9a',
  }
}

// Calculate the chart color based on the score
export const getColorNPS = (score) => {
  let chartColor = npsColors().detractor
  if (score > 49) {
    chartColor = npsColors().promoter
  } else if (score > 1) {
    chartColor = npsColors().passive
  }
  return chartColor
}

export const getColorSUS = (score) => {
  let chartColor = '#ff9a9a'
  if (score >= 68) {
    chartColor = '#00A9A7'
  } else if (score > 50) {
    chartColor = '#FFD854'
  }
  return chartColor
}

export const getPercentage = (cell, participants, toFixed = 0) => {
  const number = (cell / participants) * 100
  return number.toFixed(toFixed)
}

export const getTitle = (val) => {
  return val ? <h5>{val}</h5> : ''
}

export const getViewAll = (viewAllLink, participants, icon) => {
  return viewAllLink ? (
    <a href="#TBC" className="view-all">
      View all {participants} responses <FontAwesomeIcon icon={icon} />
    </a>
  ) : (
    ''
  )
}

export const getHighVal = (cellData, position) => {
  // get the highest value in array
  let highVal = 0
  cellData.forEach((cell) => {
    if (cell[position] > highVal) {
      highVal = cell[position]
    }
  })
  return highVal
}

export const toggleTab = (e, elems, tabs, type = '') => {
  const btWrapper = e.target.closest('div')
  const spanBtns = btWrapper.querySelectorAll('span')

  if (!e.target.classList.contains('selected')) {
    // Only toggle controls if button is inactive
    if (!type || tabs.length < 3) {
      // if not 'type' set or tabs length less than 3
      elems.forEach((elem) => {
        // We grab all elements seeing as there may be multiple related containers on the page
        const foundItems = btWrapper.closest('section').querySelectorAll(elem[0])

        foundItems.forEach((item) => {
          // toggle class values
          item.classList.toggle(elem[1])
        })
      })

      // screener EndPoint message/preiew toggle code
      const tabContext = e.target.textContent.toUpperCase()
      const otherTab = tabContext === 'EDIT' ? 'PREVIEW' : 'EDIT'

      const selectTab1 = document.querySelector(`.screener-msg-${tabContext}`)
      const selectTab2 = document.querySelector(`.screener-msg-${otherTab}`)

      if (selectTab1) selectTab1.classList.add('show')
      if (selectTab2) selectTab2.classList.remove('show')
    } else if (type === 'TEXT') {
      // if not 'type' is set to TEXT

      let classFocus = e.target.textContent.toUpperCase()
      // Remove the "S" from the end of TASKS/QUESTIONS
      classFocus =
        classFocus === 'ALL' ? classFocus : classFocus.substring(0, classFocus.length - 1)

      const liItems = btWrapper.nextSibling.querySelectorAll('ul:scope > li')

      liItems.forEach((item) => {
        // toggle class values
        if (item.classList.contains(classFocus) || classFocus === 'ALL') {
          item.classList.remove('hide')
        } else {
          item.classList.add('hide')
        }
      })
    }
  }

  spanBtns.forEach((elem) => {
    elem.classList.remove('selected')
  })
  e.target.classList.add('selected')
}

export const reportNavigation = (navData) => {
  // build this function to aid in navigation of events.
  // the function receives an array which might look like this
  // ["TASK", 2234454]
  // the second param is for the id
  let value = ''
  // a dropdown returns an array including the target & an array including the id we need
  const secondItem = Array.isArray(navData[1]) ? navData[1][1][0] : navData[1]
  switch (navData[0]) {
    case 'TASK':
      value = [navData[0], secondItem]
      break
    case 'QUESTION':
      value = [navData[0], secondItem]
      break
    case 'CLICKSTREAM':
      value = [navData[0], secondItem]
      break
    case 'VIDEO':
      value = [navData[0], secondItem]
      break
    default:
      value = [navData[0], secondItem]
  }
  return value
}

export const getStatusFlag = (project) => {
  if (project.active === false) {
    return 'Closed'
  }
  if (project.launched === true) {
    return 'Launched'
  }
  return 'Draft'
}

export const getStatusTags = (usertest) => {
  const sTags = []
  if (usertest.deleted) {
    sTags.push({ name: 'Deleted', id: 'deleted' })
  }
  if (usertest.isTeamProject) {
    sTags.push({ name: 'Team', id: 'team' })
  }

  if ([STATUS_CLOSED, STATUS_LAUNCHED, STATUS_DRAFT].includes(usertest.status)) {
    const statusName = usertest.status
    statusName.charAt(0).toUpperCase()
    sTags.push({ name: statusName, id: usertest.status })
  }

  return sTags
}

export const showProject = (filteredTags, customTags, statusTags) => {
  // does project have any tags which are in the selected list
  // of filtered tags, if not, hide them

  // check if any tags have been selected for filtering
  if (filteredTags.length) {
    let counter = 0

    customTags.forEach((tag) => {
      // if we find a project tag in the filtered tag then show the project
      if (filteredTags.findIndex((x) => x.name === tag.name) >= 0) {
        counter += 1
      }
    })

    statusTags.forEach((tag) => {
      // if we find a project tag in the filtered tag then show the project
      if (filteredTags.findIndex((x) => x.name === tag.name) >= 0) {
        counter += 1
      }
    })

    // if all our tags match the filtered tags then show row
    return counter === filteredTags.length
  }
  return true
}

export const clearAllCanvasses = () => {
  const canvasNodes = document.querySelectorAll('.canvas-container canvas')
  canvasNodes.forEach((canvasNode) => {
    const context = canvasNode.getContext('2d')
    context.clearRect(0, 0, canvasNode.width, canvasNode.height)
  })
}

// used to create the joining lines between the clickstream cells
export const createLine = (axisStart, axisEnd, stroke, isSameRow, column, color) => {
  // Define canvas and context
  const canvas = document.getElementById(`canvas-${column}`)
  const ctx = canvas.getContext('2d')

  let yStart = axisStart
  let yEnd = axisEnd

  let adjustments = [20, 0, 0]
  if (isSameRow) {
    adjustments = [20, 0, 0]
  }

  // xStart & xEnd are hardcoded
  const xStart = 16
  const xEnd = 100

  if (stroke > 30) {
    // conditional to ensure the position of the stroke is aligned correctly
    const above30 = stroke - 30
    const correction = above30 / 2
    yStart += correction
    yEnd += correction
  }

  // Define the points as {x, y}
  const start = { x: xStart - adjustments[0], y: yStart + adjustments[1] }
  const cp1 = { x: xEnd / 2, y: yStart }
  const cp2 = { x: xEnd / 2, y: yEnd }
  const end = { x: xEnd + adjustments[2], y: yEnd }

  // Cubic Bézier curve
  ctx.beginPath()
  ctx.moveTo(start.x, start.y)
  ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y)
  ctx.strokeStyle = color
  ctx.lineWidth = stroke
  ctx.stroke()
}

/**
 * @param action: either 'ADD' or 'REMOVE'
 * @param dataId: if supplied for 'ADD' action, need to add `selected` class to that question/task li elem
 *  to highlight it
 */
export const hideShowSidebar = (action = 'ADD', dataId = null) => {
  const mainWrapper = document.getElementById('create-edit-study')
  const sidebarWrapper = document.getElementById('sidebar-editor')
  if (action === 'ADD') {
    mainWrapper.classList.add('shrink')
    sidebarWrapper.classList.add('open')
    if (dataId) {
      document.querySelector(`#t-q-list li[data-id='${dataId}']`).classList.add('selected')
    }
  } else if (action === 'REMOVE') {
    mainWrapper.classList.remove('shrink')
    sidebarWrapper.classList.remove('open')

    // ensure all li elems are correctly unselected
    const allLi = document.querySelectorAll('#t-q-list li')
    allLi.forEach((li) => {
      li.classList.remove('selected')
    })
  }
}

export const scrollTo = (element, container, duration, alter = 0) => {
  const elem = document.getElementById(element)
  const containerEl = document.querySelector(container)
  const topPos = elem.offsetTop - alter

  const start = containerEl.scrollTop
  const change = topPos - start
  let currentTime = 0
  const increment = 20

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  /* eslint-disable  func-names */
  /* eslint-disable  no-param-reassign */
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    /* eslint-disable-next-line */
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  const animateScroll = () => {
    currentTime += increment
    const val = Math.easeInOutQuad(currentTime, start, change, duration)
    containerEl.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export const toggleRadioBtns = (element, array, stateUpdate) => {
  // console.log("Data pass from radio click", element);
  const radioLabel = element.clickedEl.textContent.replace(' ', '').toUpperCase()

  const tempArray = array.map((item, i) => {
    const newItem = item
    // if the value of the radio matches the array position then toggle the value
    // disabled the below line. Clicking a radio should not toggle it
    // newItem.selected = element.isChecked
    newItem.selected = i === element.value
    return newItem
  })

  stateUpdate(tempArray)

  // const timeContainer = document.getElementById('id-timeLimit')

  // Handle the hide/show of the time limit field
  // if (radioLabel === 'TIMEBOUND') {
  //   timeContainer.classList.toggle('hide')
  // } else if (radioLabel === 'STANDARD' || radioLabel === 'FIRSTCLICK') {
  //   timeContainer.classList.add('hide')
  // }

  // Handle the hide/show of the JavaScript code fields
  const jsContainer = document.getElementById('js-code-container')
  if (radioLabel === 'JAVASCRIPT') {
    jsContainer.classList.remove('hide')
  } else if (radioLabel === 'NO-CODE') {
    jsContainer.classList.add('hide')
  }
}

export const toggleCheckboxes = (element, array, stateUpdate) => {
  const tempArray = array.map((item, i) => {
    const newItem = item
    if (i === element.value) {
      // if the value of the checkbox matches the array position then toggle the value
      newItem.checked = element.isChecked
    }
    return newItem
  })

  stateUpdate(tempArray)
  return tempArray
}

export const questionTypeData = (returnBaseArray, type) => {
  // the below UUID key is only to be used as a placeholder to simulate a question
  // having been created in the backend and thus receiving a UUID to append to the question object
  const questionArr = [
    {
      type: 'multichoice1',
      name: 'Multiple Choice (1 answer)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a8',
    },
    {
      type: 'multichoicemulti',
      name: 'Multiple Choice (multiple answers)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a7',
    },
    {
      type: 'ratingscalemulti',
      name: 'Rating Scale Matrix',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a5',
    },
    {
      type: 'ratingscale1',
      name: 'Rating Scale (1 answer)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a4',
    },
    { type: 'ranking', name: 'Ranking Question', uuid: '01624c98-e7f0-3d52-9333-62724e21f7a3' },
    {
      type: 'openended1',
      name: 'Open Ended (1 item)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a2',
    },
    {
      type: 'openendedmultiple',
      name: 'Open Ended (Multiple items)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a1',
    },
    {
      type: 'openendedcomments',
      name: 'Open Ended (Comments box)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a0',
    },
    {
      type: 'netpromoterscore',
      name: 'Net Promoter Score (NPS)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a9',
    },
    {
      type: 'systemusabilityscore',
      name: 'System Usability Scale (SUS)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7a6',
    },
    {
      type: 'openendedverbal',
      name: 'Open Ended (Verbal only)',
      uuid: '01624c98-e7f0-3d52-9333-62724e21f7b0',
    },
  ]
  if (returnBaseArray) {
    return questionArr
  }

  return questionArr.filter((object) => {
    return object.type === type ? object.name : null
  })
}

export const renderTaskType = (type) => {
  let typeText = 'Standard'

  if (type === 1) {
    typeText = 'Time Bound'
  } else if (type === 2) {
    typeText = 'First Click'
  }

  return typeText
}

export const updateAnswerValue = (e, position, array, key) => {
  // save changed value
  const fieldVal = e.target.value

  return array.map((answer, i) => {
    if (position === i) {
      // this is the object we want to edit
      if (key === 'row') {
        return { ...answer, row: fieldVal }
      }
      return { ...answer, column: fieldVal }
    }
    return answer
  })
}

export const removeField = (position, array) => {
  // remove field from array
  const tempArray = array
    .filter((answer, i) => {
      return position !== i ? answer : null
    })
    .map((answer) => {
      return answer
    })

  return tempArray
}

export const addField = (array, type = false) => {
  // add a blank field
  if (type === 'row') {
    return [...array, { row: '', placeholder: '' }]
  }

  return [...array, { value: '', placeholder: '' }]
}

export const uniqueKey = (i, nameSpace = false) => {
  if (nameSpace) {
    return `${nameSpace}-${i}`
  }
  return `${Math.floor(Date.now() / 1000)}-${i}`
}

export const questionSearch = (key, value, myArray) => {
  for (let i = 0; i < myArray.length; i += 1) {
    if (myArray[i].node[key] === value) {
      return myArray[i].node
    }
  }
  return null
}

export const fadeOutModal = () => {
  const modalCont = document.getElementById('modal-container')
  const modalBody = document.getElementById('modal-body')

  modalBody.style.opacity = 0
  setTimeout(() => {
    modalCont.style.opacity = 0
  }, 200)
}

export const previewComponent = (questionData, type) => {
  const passedType = type === 'task' ? questionData.taskType : questionData.questionType
  let bodyComponent = ''
  switch (passedType) {
    case 'multichoice1':
      bodyComponent = <MultiChoice1 data={questionData} />
      break
    case 'multichoicemulti':
      bodyComponent = <MultiChoiceMulti data={questionData} />
      break
    case 'ratingscalemulti':
      bodyComponent = <RatingScaleMulti data={questionData} />
      break
    case 'ratingscale1':
      bodyComponent = <RatingScale1 data={questionData} />
      break
    case 'ranking':
      bodyComponent = <Ranking data={questionData} />
      break
    case 'openended1':
      bodyComponent = <OpenEnded1 data={questionData} />
      break
    case 'openendedmultiple':
      bodyComponent = <OpenEndedMultiple data={questionData} />
      break
    case 'openendedcomments':
      bodyComponent = <OpenEndedComments data={questionData} />
      break

    case 'netpromoterscore':
      bodyComponent = <NetPromoterScore data={questionData} />
      break
    case 'systemusabilityscore':
      bodyComponent = <SystemUsabilityScore data={questionData} />
      break
    case 'openendedverbal':
      bodyComponent = <OpenEndedVerbal data={questionData} />
      break
    default:
      bodyComponent = <Task data={questionData} />
  }

  return bodyComponent
}

export const placeholderFunc = () => {}

export const validateEmail = (email) => {
  const trimEmail = email.replace(/ /g, '') // trim any whitespace

  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(trimEmail).toLowerCase())
}

// whether or not a project needs media recording (screen/face/audio)
export const mediaPresent = (studySettings) => {
  const mediaArray = [
    studySettings.audioOption,
    studySettings.faceOption,
    studySettings.screenOption,
  ]
  return mediaArray.includes('o') || mediaArray.includes('m')
}

export const simplifyArray = (array) => {
  // remove the 'node' layer from the array
  return array.map((item) => {
    return item.node
  })
}

export const updateSaveState = (
  key,
  data,
  stateToSave,
  setSaveState,
  returnWithoutSettingState = false
) => {
  // console.log('----------------------')
  // console.log(`key: ${key}`)
  // console.log(data)
  const tempObj = stateToSave === null ? {} : cloneDeep(stateToSave)
  tempObj[key] = data

  if (returnWithoutSettingState) {
    return tempObj
  }

  setSaveState(tempObj)

  return tempObj // return the current state so it can be chained
}

export const copyLink = (id, link, setBtText) => {
  setBtText(id)
  setTimeout(() => {
    setBtText('')
  }, 3000)

  const textArea = document.createElement('textarea')
  textArea.value = link
  document.body.appendChild(textArea)

  textArea.select()
  document.execCommand('Copy')
  textArea.remove()
}

export const validateUrl = (url) => {
  const trimUrl = url.replace(/ /g, '') // trim any whitespace

  return validator.isURL(trimUrl, { require_tld: false })
}

export const validateIP = (ip) => {
  const trimIP = ip.replace(/ /g, '') // trim any whitespace
  return validator.isIP(trimIP)
}

export const validateForErrors = (input, validation) => {
  // nothing to validate
  if (input === null) {
    return [validation, 0]
  }

  const fields = Object.keys(validation)
  let errorCount = 0

  fields.forEach((field) => {
    const fieldValue = input[field]
    // reset the message to blank
    validation[field].msg = ''

    if (!fieldValue && validation[field].required) {
      // if required but empty

      errorCount += 1
      validation[field].msg = 'This field is required.'
    }
    // there is a value to check
    else if (fieldValue && fieldValue.length > 0 && validation[field].type !== 'ARRAY') {
      // if a String is expected
      if (validation[field].type === 'STRING') {
        if (validation[field].shape === 'URL' && !validateUrl(fieldValue)) {
          errorCount += 1
          validation[field].msg = `An invalid URL was detected`
        } else if (validation[field].shape === 'EMAIL' && !validateEmail(fieldValue)) {
          errorCount += 1
          validation[field].msg = `An invalid email was detected`
        }
      }
      // if a Number is expected
      else if (validation[field].type === 'NUMBER' && Number.isNaN(fieldValue)) {
        errorCount += 1
        validation[field].msg = `Please enter a valid number`
      }
      // if a CSV is expected
      else if (validation[field].type === 'CSV') {
        const array = fieldValue.split(',')

        if (validation[field].shape === 'URL') {
          // if URLs are the expected input

          const invalidUrls = []

          array.forEach((url) => {
            // check each item in the array

            if (!validateUrl(url)) {
              invalidUrls.push(url)
            }
          })

          if (invalidUrls.length > 0) {
            errorCount += 1
            validation[field].msg = `An invalid URL was detected - ${invalidUrls.join(', ')}`
          }
        }
      }
      // if a CSV is expected
      else if (validation[field].type === 'OBJECT') {
        if (!fieldValue[validation[field].shape]) {
          errorCount += 1
          validation[field].msg = `There was a problem with your uploaded image`
        }
      }
    } else if (validation[field].type === 'ARRAY') {
      if (validation[field].shape === 'IP') {
        // validating IPs used in UP ranges of study settings
        const invalidIps = []

        if (validation[field].required && fieldValue.length < 1) {
          // if restrict IP is not '' then its required & we need IPs
          errorCount += 1
          validation[
            field
          ].msg = `Please enter some IPs or change 'Restrict Participant IPs' setting to 'No restrictions.'`
        } else if (fieldValue) {
          fieldValue.forEach((item) => {
            if (!validateIP(item.ipAddressStart)) {
              invalidIps.push(item.ipAddressStart)
            }
            if (!validateIP(item.ipAddressEnd)) {
              invalidIps.push(item.ipAddressEnd)
            }
          })

          if (invalidIps.length > 0) {
            errorCount += 1
            validation[field].msg = `An invalid IP was detected - ${invalidIps.join(', ')}`
          }
        }
      } else {
        // error validation within questions that utilise rows or columns in DB

        let key = 'row'
        switch (field) {
          case 'columns':
            key = 'column'
            break
          default:
        }
        // first remove any blank rows
        const cleanArray = fieldValue.filter((item) => {
          return item[key] ? item : false
        })

        if (validation[field].required && cleanArray.length < 1) {
          errorCount += 1
          validation[field].msg = `This field is required.`
        }
      }
    }
  })

  return [validation, errorCount]
}

export const setBottomCss = () => {
  const bannerContainer = document.querySelector('.save-cancel-banner')
  if (bannerContainer === null) {
    return
  }

  const mainContainer = bannerContainer.closest('.settings-container')
  const maxNumber = mainContainer.offsetHeight

  const getMargin = (number) => {
    const seperator = mainContainer.classList.contains('study-settings') ? 32 : 16

    const calc = number - seperator
    if (calc > maxNumber + 120) {
      return `-${maxNumber + 120}px`
    }

    return calc > 6 ? `-${calc}px` : `${seperator}px`
  }

  bannerContainer.style.bottom = getMargin(mainContainer.scrollTop)
}

export const scrollReader = (container) => {
  // positioning the cancel/save buttons dynamically at the bottom of the container
  // mirroring the 'fixed' css, however cannot get purely css to work in this scenario
  setTimeout(() => {
    setBottomCss()

    container.addEventListener('scroll', setBottomCss, true)
  }, 100)
}

export const toCamel = (s) => {
  // takes snake_case and turns it into snakeCase
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export function toggleTour() {
  const { body } = document
  const container = document.getElementById('tour-container')

  body.classList.toggle('disable-scroll')
  container.classList.toggle('show-tour')

  if (container.classList.contains('show-tour')) {
    // Slight delay required in order to make fadein of modal smooth
    setTimeout(() => {
      container.style.opacity = 1
    }, 100)
  } else {
    container.style.opacity = 0
  }
}

export const stripHash = (location, object = true) => {
  const string = object ? location.hash : location
  return string.replace('#', '')
}

export const mainTourHashs = [
  'start-tour',
  'loop11-dashboard',
  'create-a-project',
  'tasks-and-questions',
  'adding-questions',
  'adding-tasks',
  'tour-study-settings',
  'tour-selecting-participants',
  'tour-launching-a-project',
  'analyzing-a-report',
  'working-with-segments',
  'working-with-themes',
]

export const snakeUpperCase = (string) => {
  return string.replace(' ', '_').toUpperCase()
}

export const buildSelectedArray = (data, options, blankItem, childName = '') => {
  const tempArray = cloneDeep(data)
  const buildSelected = []
  const children = !!blankItem.children
  let childObj = {}
  /*
    To ensure our selected array count matches the available options, we rebuild the
    array count once component has mounted & pass it back to the parent as an updated State.
    THis is done to help identify if changes have been made, and thus 'saving' required
  */

  // Loop through the available options to build a 'selected' array with the same count
  options.forEach((item) => {
    // Seperate out items from the 'selected' data and rebuild/reinsert them in the correct position
    if (
      data.selected.find((element) => {
        childObj = element.children
        return element.name === item.name
      })
    ) {
      // We have a match so return the object with values
      const newObject = { name: item.name, id: snakeUpperCase(item.name) }

      // if children are apart of this object's structure, insert them into the object too
      if (children) {
        newObject.children = []
        /*
            Like with the parents, we need to ensure we render the correct number of array items
            so we loop through the available children options to rebuild the child array
          */
        item[childName].forEach((childItem) => {
          if (childObj.find((child) => child.name === childItem.name)) {
            // we have a value so insert it
            newObject.children.push({
              name: childItem.name,
              id: snakeUpperCase(childItem.name),
            })
          } else {
            // no value present so insert blank object
            newObject.children.push({ name: '', id: '' })
          }
        })
      }
      // insert rebuilt object
      buildSelected.push(newObject)
    } else {
      // We have no match so insert the blank object
      buildSelected.push(blankItem)
    }
  })

  tempArray.selected = buildSelected

  return tempArray
}
