import React from 'react'
import PropTypes from 'prop-types'
import reactHtmlParser from 'react-html-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
/* eslint-disable import/no-cycle */
import { uniqueKey } from '../../../utilities/base'

const Ranking = (props) => {
  const { data } = props

  const getRankingCells = (answer, length, header = false) => {
    const html = []
    let count = 0

    html.push(<div key={count}>{answer.row}</div>)

    while (count < length) {
      if (header) {
        html.push(<div key={`head-${count}`}>{count + 1}</div>)
      } else {
        html.push(
          <span key={`head-${count}`}>
            <FontAwesomeIcon icon={faCircle} className="close-modal" />
          </span>
        )
      }
      count += 1
    }

    return html
  }

  const generateCss = (length) => {
    let css = '2fr '
    let count = 0

    while (count < length) {
      css += '1fr '
      count += 1
    }
    return { gridTemplateColumns: css }
  }

  const inlineCss = generateCss(data.rows.length)

  return (
    <React.Fragment>
      <div className="question-container">{reactHtmlParser(data.question)}</div>
      <ul className="preview-lists ranking">
        <li className="grid" style={inlineCss}>
          {getRankingCells('', data.rows.length, true)}
        </li>
        {data.rows.map((answer, i) => {
          return (
            <li key={uniqueKey(i)} className="grid" style={inlineCss}>
              {getRankingCells(answer, data.rows.length)}
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

Ranking.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Ranking
