import React from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import reactHtmlParser from 'react-html-parser'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const Banner = ({ type, id, msg, linkTo }) => {
  const alertClass = `alert-${type}`

  const alertIcon = <FontAwesomeIcon icon={faInfoCircle} />

  const divStyle = {
    zIndex: 15 - id,
  }
  if (linkTo) {
    divStyle.cursor = 'pointer'
  }

  const children = (
    <div
      className={alertClass}
      style={divStyle}
      onClick={() => {
        if (linkTo) {
          window.location.href = linkTo
        }
      }}
    >
      <span data-name="spacer" />
      <div>
        {alertIcon} {reactHtmlParser(msg)}
      </div>
    </div>
  )
  return ReactDOM.createPortal(children, document.getElementById('banner-container'))
}

Banner.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  msg: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
}

Banner.defaultProps = {
  linkTo: null,
}

export default Banner
