import React, { useState } from 'react'
import { useBoolean, useInput } from 'react-hanger/array'
import { Box, Flex } from 'reflexbox'
import axios from 'axios'
// Components
import InputText from '../../components/form/InputText'
import Button from '../../components/form/Button'
import loop11Logo from '../../components/logo.svg'
import LoginMarketingPane from './2023/LoginMarketingPane'
import { useAlerts } from '../../components/alert_anon_manager'

const Verify2FA = () => {
  const { showAlert } = useAlerts()
  const [loading, loadingActions] = useBoolean(false)

  const [[token], tokenActions] = useInput('')

  const [errorMsg, setErrorMsg] = useState(null)

  const canEnableSubmitBtn = () => {
    return !loading && token && token.length === 6
  }

  const submitForm = (e) => {
    e.preventDefault()
    loadingActions.setTrue()
    setErrorMsg(null)
    axios
      .post(`/api/account/auth/verify_2fa/${window.location.search}`, {
        token,
      })
      .then((resp) => {
        window.location.href = resp.data.redirect_url || '/dashboard/'
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const resp = error.response.data
          if (resp.detail) {
            setErrorMsg(resp.detail)
          } else {
            showAlert('ERROR', 'Something went wrong. Please try again')
          }
        }
      })
      .finally(() => {
        loadingActions.setFalse()
      })
  }

  const getHelp = () => {
    // eslint-disable-next-line new-cap
    window.Intercom(
      'showNewMessage',
      // eslint-disable-next-line max-len
      'I am having a problem with the two-factor authentication method linked to my Loop11 account. I understand that, to help me regain access to my account, I agree to allow Loop11 to authenticate my account ownership and turn off my two-factor authentication method.'
    )
  }

  return (
    <form onSubmit={submitForm}>
      <Flex flexWrap="wrap" justifyContent="center">
        <Box width={[1, 2 / 5]}>
          <div className="signup-container-2023">
            <div className="intro">
              <img src={loop11Logo} alt="Loop11" className="loop11-logo" />

              <h3 className="title">Enter your authentication code</h3>
            </div>

            <div className="signup-email-wrapper">
              <div>
                <p>Use your mobile authenticator app to generate a code and enter it below.</p>
                <InputText
                  type="text"
                  label="Authentication code"
                  name="token"
                  value={token}
                  placeholder="123456"
                  handleChange={tokenActions.onChange}
                  className="inpt-signup"
                  error={!!errorMsg}
                  errorMsg={errorMsg || 'Invalid code. Please try again.'}
                  disabled={loading}
                />

                <Button
                  type="submit"
                  className="signup"
                  label="Submit"
                  clickHandler={submitForm}
                  disabled={!canEnableSubmitBtn()}
                />
              </div>
            </div>
            <div>
              <p>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={() => getHelp()}>
                  Need help?
                </a>
              </p>
              <p>
                <a href="/account/logout/?next=/dashboard/login/">Start again.</a>
              </p>
            </div>
          </div>
        </Box>

        <LoginMarketingPane />
      </Flex>
    </form>
  )
}

export default Verify2FA
